/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    .scrollbar-hide {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
    }
  
    .scrollbar-hide::-webkit-scrollbar {
      display: none;  /* Chrome, Safari, and Opera */
    }

  }
  